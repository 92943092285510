$(document).ready(function () {
    var currentNum = $(".count-number").text();
    var pointNum = $('.list-gift li').length;
    activePoint(50000, currentNum, 500000, pointNum);
  
});
function activePoint(minNum, currentNum, maxNum, pointNum) {
    if (currentNum >= minNum) {
        var milesTones = [500000,300000, 200000,100000,50000];
        var endPoint = 0;
        for (var i = 0; i < milesTones.length; i++) {
            if (currentNum >= milesTones[i]) {
                endPoint = pointNum - i;
                break;
            }
        }
        $('.list-gift li').removeClass('active');
    
        for (var i = 0; i < endPoint; i++) {   
            $('.list-gift li:eq(' + i + ')').addClass('active');         
        }
        if (endPoint == 1) {
            $('.current-progess').addClass('width-1');
        }
        else if (endPoint == 2) {    
            $('.current-progess').addClass('width-2');

        }
        else if (endPoint == 3) {      
            $('.current-progess').addClass('width-3');

        }
        else if (endPoint == 4) {
            $('.current-progess').addClass('width-4');
        }
        else if (endPoint == 5) {
            $('.current-progess').addClass('width-5');

        }
    }
    else {

    }
}
