var target;
var control;
import Swiper from "Swiper";
import "swiper/css/swiper.min.css";

var swipernv = new Swiper(".nv-swiper", {
  grabCursor: true,
  centeredSlides: true,
  effect: "fade",
  pagination: {
    el: ".nv-pag",
    clickable: true,
  },
});

var swiperf6 = new Swiper(".nd-swiper", {
  pagination: {
    el: ".swiper-pagination.nd-pag",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next.nd-next",
    prevEl: ".swiper-button-prev.nd-prev",
  },
  effect: "coverflow",
  loop: true,
  centeredSlides: true,
  coverflowEffect: {
    rotate: 0,
    stretch: 100,
    depth: 1000,
    slideShadows: true,
  },
});

// var f4_tab1_swiper = new Swiper('.f4-tab1-swiper', {
//   grabCursor: true,
//   effect :"fade",
//   pagination: {
//     el: '.f4-tab1-swiper-pag',
//     clickable: true,
//   },
//   loop: true,
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//     loadPrevNextAmount: 1,
//   },
//   slidesPerView: 1,
// });
