if ($(".popup__background").length > 0) {
  $(".popup__background").each(function () {
    $(this).on("click", function () {
      $(this).parent().removeClass("active");
    });
  });
}
$(".close-popup,.btn-close").on("click", function () {
  $(".popup").removeClass("active");
});
var target;
$(".btn-openpopup").on("click", function () {
  target = $(this).data("target");
  $(target).addClass("active");
});
