import Swiper from "Swiper";

var swiperEvent = new Swiper(".swiper-container.event-swiper", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".event-swiper .swiper-button-next",
    prevEl: ".event-swiper .swiper-button-prev",
  },
  pagination: {
    el: ".event-swiper .swiper-pagination",
    clickable: true,
  },
});

var swiperFeature = new Swiper(".swiper-container.feature-swiper", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".feature-swiper .swiper-button-next",
    prevEl: ".feature-swiper .swiper-button-prev",
  },
  pagination: {
    el: ".feature-swiper .swiper-pagination",
    clickable: true,
  },
});

var swiperChar = new Swiper(".swiper-container.char-swiper", {
  spaceBetween: 10,
  noSwiping: true,
  noSwipingClass: "swiper-slide",
  effect: "fade",
  pagination: {
    el: ".char-swiper .swiper-pagination.char-pagination",
    clickable: true,
  },
});

var swiperChar1 = new Swiper(".swiper-container.char-1-swiper", {
  spaceBetween: 10,
  effect: "fade",
  pagination: {
    el: ".char-1-swiper .swiper-pagination",
    clickable: true,
  },
});

var swiperChar2 = new Swiper(".swiper-container.char-2-swiper", {
  spaceBetween: 10,
  effect: "fade",
  pagination: {
    el: ".char-2-swiper .swiper-pagination",
    clickable: true,
  },
});

var swiperChar3 = new Swiper(".swiper-container.char-3-swiper", {
  spaceBetween: 10,
  effect: "fade",
  pagination: {
    el: ".char-3-swiper .swiper-pagination",
    clickable: true,
  },
});

var swiperChar4 = new Swiper(".swiper-container.char-4-swiper", {
  spaceBetween: 10,
  effect: "fade",
  pagination: {
    el: ".char-4-swiper .swiper-pagination",
    clickable: true,
  },
});

var target_tab;
$(".monphai-tab-item").click(function (e) {
  e.preventDefault();
  $(this).siblings().removeClass("active");
  $(this).addClass("active");
  target_tab = $(this).data("target");
  $(this)
    .parent()
    .siblings()
    .find(".monphai-info--feature-item")
    .removeClass("active");
  $(this)
    .parent()
    .siblings()
    .find(".monphai-info--feature-item-" + target_tab)
    .addClass("active");
});

$(".nv-tab .tab").on("click", function (e) {
  e.preventDefault();
  $(this).parent().find(".tab").removeClass("active");
  let open = $(this).data("open");
  let close = $(this).data("close");
  $(`.${open}`).addClass("active");
  $(`.${close}`).removeClass("active");
  $(this).addClass("active");
});

$(".btn-sound").on("click", function (e) {
  e.preventDefault();
  $(this).toggleClass("off");
});

$(".btn-popup").on("click", function (e) {
  e.preventDefault();

  let targer = $(this).data("target");
  $(targer).addClass("active");
});

let urlJSONP = ""; // link call JSONP

$(document).ready(function () {
  $.ajax({
    url: urlJSONP,
    dataType: "jsonp",
    success: function (dataResponse) {
      let numberWithDot = dataResponse
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      $(".regis-block .count-register").attr("data-number", numberWithDot);
      $(".regis-block .count-register").data("number", numberWithDot);
    },
  });
});
